<template>
  <div class="mydata">
    <van-nav-bar
      :title="$t('MYDATA')"
      @click-left="onClickLeft"
    >
    <van-icon color="#fff" size="25" slot="left" name="arrow-left" />
    </van-nav-bar>
    <div class="tips">{{$t('MSUTDATAFULL')}}</div>
    <div class="main">
      <van-cell-group>
        <van-cell to="/my/mydata/identitydata" center>
          <div slot="icon">
            <van-image class="dataimg margin_10" width="35" height="35" :src="Aimg" />
          </div>
          <div class="title" slot="title">{{$t('IDENTITYDATA')}}</div>
          <div class="" slot="label">*{{$t('UNDERSTANDBASICINFORMATION')}}</div>
          <div slot="default">{{stutaObj.identity}}</div>
          <van-icon size="20" slot="right-icon" name="arrow" />
        </van-cell>
        <van-cell to="/my/mydata/datamessage" center>
          <div slot="icon">
            <van-image class="dataimg margin_10" width="35" height="35" :src="Cimg" />
          </div>
          <div class="title" slot="title">{{$t('DATAMESSAGE')}}</div>
          <div class="" slot="label">*{{$t('UNDERSTANDBASICINFORMATION')}}</div>
          <div slot="default">{{stutaObj.information}}</div>
          <van-icon size="20" slot="right-icon" name="arrow" />
        </van-cell>
        <van-cell to="/my/mydata/receiptBankCard" center>
          <div slot="icon">
            <van-image class="dataimg margin_10" width="35" height="35" :src="Dimg" />
          </div>
          <div class="title" slot="title">{{$t('RECEIPTBANKCARD')}}</div>
          <!-- <div class="" slot="label">*让我们了解您的基本情况</div> -->
          <div slot="default">{{stutaObj.bank}}</div>
          <van-icon size="20" slot="right-icon" name="arrow" />
        </van-cell>
        <!-- <van-cell to="/my/mydata/furtherinformation" center>
          <div slot="icon">
            <van-image class="dataimg margin_10" width="35" height="35" :src="Bimg" />
          </div>
          <div class="title" slot="title">{{$t('FURTHERINFORMATION')}}</div>
          <div class="" slot="label">*{{$t('REVIEWTHEPASSRATE')}}</div>
          <div slot="default">{{stutaObj.workProof}}</div>
          <van-icon size="20" slot="right-icon" name="arrow" />
        </van-cell> -->
        <van-cell to="/signature" center>
          <div slot="icon">
            <van-image class="dataimg margin_10" width="35" height="35" :src="Eimg" />
          </div>
          <div class="title" slot="title">tandatangan</div>
          <!-- <div class="" slot="label">*让我们了解您的基本情况</div> -->
          <div slot="default">{{stutaObj.autograph}}</div>
          <van-icon size="20" slot="right-icon" name="arrow" />
        </van-cell>
      </van-cell-group>
    </div>
    <!-- <div class="subbox">
      <van-button class="sub" color="#00BFFF" type="primary" block>{{$t('ATONECBORROWMONEY')}}</van-button>
    </div> -->
  </div>
</template>

<script>
import { myInformation } from '@/api/my'
import './css/my.css'
export default {
  name: 'mydata',
  data () {
    return {
      stutaObj: '',
      Aimg: require('./img/g_03.png'),
      Bimg: require('./img/g_06.png'),
      Cimg: require('./img/g_08.png'),
      Dimg: require('./img/g_14.png'),
      Eimg: require('./img/dianziqianming.png')
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    onClickLeft () {
      this.$router.replace('/my')
    },
    async loadData () {
      const { data } = await myInformation()
      const obj = {}
      for (const key in data.data) {
        obj[key] = data.data[key] === true ? this.$t('FILLED') : this.$t('UNFILLED')
      }
      this.stutaObj = obj
      console.log(this.stutaObj.identity)
    }
  }
}
</script>

<style scoped>
.tips {
  text-align: center;
  padding: 10px;
  font-size: 13px;
  color: #666;
}
.main {
  box-sizing: border-box;
  padding: 0 10px;
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
}
.margin_10 {
  margin-right: 10px;
}
.title {
  font-size: 16px;
}
.subbox {
  padding: 20px;
}
.sub {
  font-size: 18px;
}
.mydata >>> .van-nav-bar__content {
  background-color: #759FED;
  /* color: #fff; */
}
.mydata >>> .van-nav-bar__title {
  color: #fff;
}
</style>
